<script setup>
import { useForm } from "@inertiajs/vue3";
import { useDebounceFn } from "@vueuse/core";
import { computed, inject } from "vue";
import { useI18n } from "vue-i18n";

// Components
import Pagination from "@/Components/Pagination/PaginationFE.vue";
import Filters from "@/Components/Table/Filters.vue";
import Table from "@/Components/Table/Table.vue";

// Layouts
import LocationLayout from "@/Layouts/LocationLayout.vue";

const { t } = useI18n();
const route = inject("route");
const props = defineProps({
  query: Object,
  invoices: Object,
  statusOptions: Object
});

const typeFilters = {
  status: {
    options: {
      "": t("All {attributes}", { attributes: t("statuses") }),
      ...props.statusOptions
    }
  }
};

const form = useForm({
  search: props.query.search ?? "",
  orderBy: props.query.orderBy ?? "due_date",
  orderDir: props.query.orderDir ?? "desc",
  status: props.query.status ?? "",

  page: props.query.page ?? 1
});

const getData = (resetPage = true) => {
  form.page = resetPage ? 1 : form.page;

  form.get(route("location.invoices.index"), {
    preserveState: true,
    preserveScroll: true,
    only: ["invoices", "query"]
  });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = [
  { label: t("Status"), width: "20%" },
  { label: t("Invoice number"), cell: "Bold" },
  { label: t("Date"), orderBy: "employer" },
  { label: t("Due date"), orderBy: "due_date" },
  { label: "", cell: "Download", width: "5%" },
  { label: "", cell: "Arrow", width: "2%" }
];

const tableData = computed(() =>
  props.invoices.data.map(invoice => {
    return {
      id: invoice.id,
      colorCode: invoice.status == "open" ? "#F89B29" : "#1BB21B",
      rowData: [
        invoice.status,
        invoice.number,
        invoice.date,
        invoice.due_date,
        route("location.invoices.download", invoice.id),
        route("location.invoices.show", invoice.id)
      ]
    };
  })
);

const setPage = e => {
  form.page = e;
  getData(false);
};
</script>

<template>
  <LocationLayout>
    <Filters
      :form="form"
      @anyUpdate="getData()"
      :h1="$t('Invoices')"
      :data="invoices"
      :typeFilters="typeFilters"
    />
    <template v-if="typeof invoices !== 'undefined' && invoices.data.length > 0">
      <Table
        class="mb-8"
        :loading="form.processing"
        :tableSettings="tableSettings"
        :data="tableData"
        :footerFaded="query.search != '' && query.search != null"
        v-model:orderBy="form.orderBy"
        @update:orderBy="getData()"
        v-model:orderDir="form.orderDir"
        @update:orderDir="getData()"
      />

      <Pagination
        v-if="typeof invoices !== 'undefined'"
        :links="invoices.links"
        @setPage="setPage"
        :pageData="invoices"
        :hideTotal="query.search != '' && query.search != null"
      />
    </template>
    <template v-else>
      <div
        class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded"
      >{{ $t('No {model} found', { model: $t('invoices') }) }}</div>
    </template>
  </LocationLayout>
</template>
